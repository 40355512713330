<template>
  <div id="centreLeft1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="chart-pie"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2" style="font-size:24px;">车间产量</span>
          <dv-decoration-1 style="width:1.25rem;height:.25rem; position:relative;top:-.0375rem;" />
        </div>
      </div>
        <div class="d-flex jc-center body-box"  style="height:80%; font-size: 0.4rem;color:#ffc103" >
        <dv-scroll-board :config="config1"  />
      </div>
    </div>
  </div>
</template>

<script>
import {selectSeqYieldByDateNow} from '../../libs/axios';
export default {
  data() {
    return {
      config1: {
        rowNum: 5, //表格行数
        header: ["工序", "完成数", "返工数", "返工率"],
        data:[],
        headerHeight: 65,
        headerBGC: "#0f1325", //表头
        oddRowBGC: "#0f1325", //奇数行
        evenRowBGC: "#171c33", //偶数行
        index: true, 
        columnWidth: [120,780,230,220,240],
        align: ["center"]
      }
    };
  },
  components: {
  },
  mounted() {    this.init();
     setInterval(this.init,120000);},
  methods: {
   init() {
      this.getWageList();
    },
     getWageList() {
      this.loading = true;
      var seqGuids=this.$store.state.seqWageGuids;
       var deptId=this.$store.state.deptId;
      selectSeqYieldByDateNow({deptId:deptId,seqGuids:seqGuids}).then((res) => {
          this.loading = false;
          this.$set(this.config1,  this.config1.data,res.data);
          this.config1.data=res.data;
      });
    },
  }
};
</script>

<style lang="scss">
#centreLeft1 {
  padding: 0.2rem;
  height: 5.225rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 4.8125rem;
    border-radius: 0.125rem;
    font-size:0.4rem;
  }
  .text {
    font-size: 0.4rem;
    color: #c3cbde;
  }
  .body-box {
    border-radius: 0.125rem;
    overflow: hidden;
    font-size: 0.4rem;
  }
  .header-item{
    font-size: 0.3rem;
  }
    .row-item{
    font-size: 0.4rem;
    color:"#ffc103"
  }
  .ceil{
    color: red;
  }
}
</style>