<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './centerRight2Chart.vue';
import {getDay7WageEchartsWorkShop} from '../../../../libs/axios';
export default {
  data () {
    return {
      cdata:"",
    }
  },
  methods: {
     init() {
      this.getDay7WageEchartsList();
    },
   getDay7WageEchartsList() {
      this.loading = true;
       var workid=this.$store.state.workid;
      var seqWageGuids=this.$store.state.seqWageGuids;
      var deptId=this.$store.state.deptId;
      getDay7WageEchartsWorkShop({deptId:deptId,workid:workid,seqGuids:seqWageGuids}).then((res) => {
        // vm.$store.commit("setMessageType", res.result);
        //  alert(JSON.stringify(res.data));
        this.loading = false;
          this.cdata=res.data;
      });
    },
  },
  components: {
    Chart,
  },
  mounted () {
    this.init();
    setInterval(this.init,120000);
  },
}
</script>

<style lang="scss" scoped>
</style>